import munkres from 'munkres-js'
import React from 'react';

const Hungarian = ({projects, preferences}) => {
	if (Object.keys(projects).length === 0) {
		return <i>No projects selected.</i>
	}
	if (Object.keys(preferences).length === 0) {
		return <i>No preferences selected.</i>
	}

	let places = [];
	for (let project in projects) {
		for (let i = 0; i < projects[project]; i++) {
			places.push(project);
		}
	}

	let maxPref = Object.keys(projects).length;
	let matrix = [];
	for (let group in preferences) {
		let values = places.slice().map(project => {
			let idx = preferences[group].indexOf(project);
			if (idx === -1) idx = maxPref;
			return idx;
		});
		matrix.push(values);
	}

	let assignment = munkres(matrix);

	let groupNames = Object.keys(preferences);

	return <ul>
		{assignment.map(([group, project]) => {
			return <li>
				{groupNames[group]}: {places[project]}
			</li>
		})}
	</ul>;
};

export default Hungarian