import React from 'react';
import './style.css'
import FileUpload from "./FileUpload";
import Validator from "./Validator";
import Hungarian from "./Hungarian";

class App extends React.Component {
	state = {
		projects: {},
		preferences: {}
	};

	constructor(props) {
		super(props);
		this.changeProjects = this.changeProjects.bind(this);
		this.changePreferences = this.changePreferences.bind(this);
	}

	changeProjects(csv) {
		let projects = {};
		this.setState({projects});
		for (let line of csv) {
			if (line.length > 2) {
				alert(`Cannot load projects, '${line[0]} has more than one property.'`);
				return;
			}
			projects[line[0]] = parseInt(line[1]);
		}
		this.setState({projects})
	}

	changePreferences(csv) {
		let preferences = {};
		this.setState({preferences});
		for (let [group, ...list] of csv) {
			preferences[group] = list;
		}
		this.setState(preferences);
	}

	render() {
		return <>
			<section>
				<h1>Projects</h1>
				<FileUpload
					onChange={this.changeProjects}
				/>
			</section>
			<section>
				<h1>Preferences</h1>
				<FileUpload
					onChange={this.changePreferences}
				/>
			</section>
			<section>
				<h1>Errors</h1>
				<Validator
					preferences={this.state.preferences}
					projects={this.state.projects}
				/>
			</section>
			<section>
				<h1>Assignment</h1>
				<Hungarian
					preferences={this.state.preferences}
					projects={this.state.projects}
				/>
			</section>
		</>;
	}
}

export default App;
