import React from "react";

class FileUpload extends React.Component {
	constructor(props) {
		super(props);
		this.onChange = this.onChange.bind(this);
	}

	onChange(evt) {
		let file = evt.target.files[0];
		let reader = new FileReader();
		reader.onload = readerEvt => {
			let arr = readerEvt.target.result
				.split("\n")
				.map(line => line.split(";"));
			this.props.onChange(arr);
		};
		reader.readAsText(file);
	}

	render() {
		return <input type="file" onChange={this.onChange}/>;
	}
}

export default FileUpload;