import React from "react";

const Validator = ({projects, preferences}) => {
	let errors = [];

	let totalPlaces = 0;
	for (let project in projects) {
		let places = projects[project];
		if (places < 1) {
			errors.push(`Project '${project}' has no assignable places.`);
		} else {
			totalPlaces += places;
		}
	}

	if (totalPlaces < Object.keys(preferences).length) {
		errors.push(`There are ${totalPlaces} available spots, but ${Object.keys(preferences).length} groups are uploaded.`);
	}

	let seenProjects = Object.keys(projects);
	for (let group in preferences) {
		let list = preferences[group];
		for (let item of list) {
			if (seenProjects.indexOf(item) === -1) {
				errors.push(`Group '${group}' has listed project '${item}' as a preference, but no such project was found.`);
				seenProjects.push(item);
			}
		}
	}

	if (errors.length === 0) {
		return <i>No errors were found.</i>;
	} else {
		return <ul>
			{errors.map(item => <li>{item}</li>)}
		</ul>
	}
};

export default Validator;